import parse from "html-react-parser";
import React from "react";
import Head from "next/head";
import Layout from "../components/layout";
import { SectionBuilder } from "../components";
import { getData, getPostTypeList } from "../services/API";

export const getStaticProps = async ({ params, locale, preview }) => {
  const entities = ["getPage"];
  const { page, mainMenuData, footerData } = await getData({
    entities,
    id: params.id,
    locale,
    preview,
  });

  return {
    props: {
      mainMenuData,
      componentsData: page[0].acf,
      seoData: page[0].yoast_head,
      seoSchema: page[0].yoast_head_json.schema,
      reelData: page[0].reel,
      footerData,
      hrefLangs: page[0].hreflangs,
      isPreview: !!preview
    },
    revalidate: 30,
  };
};

export const getStaticPaths = async ({ locales }) => {
  const entities = ["pages"];
  const [pages] = await Promise.all(
    entities.map((ent) => getPostTypeList(ent, locales))
  );

  return {
    paths: pages,
    fallback: false,
  };
};

const Pages = ({
  componentsData,
  footerData,
  mainMenuData,
  seoData,
  seoSchema,
  reelData,
  hrefLangs,
  isPreview
}) => {
  const yoastHead = parse(seoData);

  return (
    <>
      <Layout
        footerData={footerData}
        mainMenuData={mainMenuData}
        reelData={reelData}
      >
        <Head>
          {yoastHead}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(seoSchema) }}
          />

          {/* {hrefLangs[0] && (
            <link
              rel="alternate"
              href={hrefLangs[0].url}
              hrefLang={hrefLangs[0].lang_code}
            />
          )}
          {hrefLangs[1] && (
            <link
              rel="alternate"
              href={hrefLangs[1].url}
              hrefLang={hrefLangs[1].lang_code}
            />
          )}
          {hrefLangs[0] && (
            <link
              rel="alternate"
              href={hrefLangs[0].url}
              hrefLang="x-default"
            />
          )} */}
        </Head>

        <SectionBuilder data={componentsData} />
      </Layout>
    </>
  );
};
export default Pages;
