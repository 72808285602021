import { useEffect } from "react";
import Meta from "../components/meta";

export default function Layout({ children, footerData }) {
  const isTouch = () => {
    if (
      "ontouchstart" in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
      document.body.classList.add("is-touch");
    else document.body.classList.add("no-touch");
  };

  useEffect(() => {
    if (window) {
      setTimeout(() => {
        isTouch();
      }, 0);
    }
  }, []);

  return (
    <>
      <Meta />
      <main>{children}</main>
    </>
  );
}
